<template>
  <div id="text-center">
    <div class="flex justify-between p-2 sm:p-5 bg-white w-full">
      <img
        src="@/assets/images/logo2.svg"
        alt="SmartMatch Logo"
      >
    </div>
    <div class="overflow-y-auto bg-gray-50">
      <div
        v-if="alertMessage != ''"
        class="flex justify-center items-center mt-8 z-10"
      >
        <div
          class="w-1/4 p-4 mb-4 text-sm text-white-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-white-800"
          role="alert"
        >
          <div class="">
            {{ alertMessage }}
          </div>
          <span class="">
            <svg
              class="h-6 w-6 cursor"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
              @click="userStore.setAlertMessage('')"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </div>
      </div>

      <slot />
    </div>
    <LayoutsFooter />
  </div>
</template>

<script setup>
import { useUserStore } from "~/store/user";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { alertMessage } = storeToRefs(userStore);
</script>

<style lang="postcss" scoped>
.cursor {
	cursor: pointer;
}
#grid-container {
	display: grid;
	width: 100vw;
	height: 100vh;
	grid-template-columns: auto;
	grid-template-rows: 100px auto 88px;
}
</style>
